<template>
    <div class="rounded-full select-none bg-primary-main text-white">
    <p class="">{{content}}</p>
</div>

</template>

<script>
export default {
    name: "neo-badge",
    components: {},
    props: ["content", "bg", "color"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};

</script>

<style scoped>
/* .badge {
    padding: 0.4rem 1rem;
    width: fit-content;
} */
.bg-primary-main {
    background-color: var(--theme-color-main);
}
</style>