<template>
    <span
        v-tippy
        content="Add Entity"
        class="flex items-center justify-center w-5 h-5 p-0 rounded-full cursor-pointer add-new-entity-button"
        @click="$emit('click')"
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="16" height="16" class="add-new-entity-button__icon">
            <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"/>
        </svg>
    </span>
</template>

<script>
export default {
    name: "AddNewEntityButton",
}
</script>


<style lang="scss" scoped>
.add-new-entity-button {
    &__icon {
        transform: rotate(90deg);
    }
}
</style>
