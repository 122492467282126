<template>
    <neo-layout
        :loading="loading"
        :show-loader="false"
        height="fit-content"
    >
        <CanvasMenu
            v-if="isEditMode"
            :mainEntity="mainEntities"
            :individualData="entityToEdit"
            :loading="loaders.entityData"
            mode="main-entity"
            @closeCanvas="finishEdit"
            @updateCardStatus="updateCardStatus"
        />
        <entity-tool-bar
            :items="computedEntitiesList"
            :plus-disabled="plusDisabled"
            editable
            @select="handleEntityClick($event)"
            @add="$emit('add')"
            @edit="handleEntityEdit"
        />
    </neo-layout>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
import EntityToolBar from "../components/EntityToolBar";
import NeoLayout from "@shared/components/neo-layout";
const CanvasMenu = () => import("@shared/saas-components/canvas-menu");

export default {
    name: "EntityToolBarContainer",

    components: {
        EntityToolBar,
        NeoLayout,
        CanvasMenu,
    },

    props: {
        caseEntitiesData: {
            type: Object,
            required: true,
        },
        // used to work with temporary data tah isn't present in original caseEntitiesData
        items: {
            type: Array,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        // enables "add" ("plus") button:
        plusDisabled: {
            type: Boolean,
            default: false,
        },
        mainEntities: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            isEditMode: false,
            entityToEdit: null,
            loaders: {
                entityData: false,
            },
        }
    },

    computed: {
        ...mapGetters(["getNeoCaseId"]),

        caseId() {
            return this.$route.query.caseid;
        },
        entityId() {
            return this.$route.query.entityId;
        },
        computedEntitiesList() {
            const { case_entities, case_entity_type_info } = this.caseEntitiesData;

            const list = this.items ?? case_entities;

            return list?.map(entity => ({
                id: entity.id,
                data: entity,
                collect_data: entity.collect_data,
                name: entity.entity_name,
                type: case_entity_type_info?.[entity.entity_type_id]?.name,
                selectable: true,
                readonly: true,
                disabled: false,
                selected: entity.id === this.entityId,
                checked: false,
            }));
        },
        caseEntity() {
            return this.computedEntitiesList?.find(item => item.id === this.entityId);
        },
    },

    watch: {
        caseEntitiesData: {
            handler(val) {
                if (!this.$route.query.entityId) {
                    const caseEntityId = val?.case_entities
                        ?.find(ce => !this.mainEntities.find(me => me.id === ce?.id))?.id;
                    return this.redirectToEntity(caseEntityId ?? val?.case_entities?.[0]?.id);
                }

                this.setCaseEntities();
            },
            deep: true,
        },
    },

    methods: {
        setCaseEntities() {
            if (this.entityId !== this.caseEntity?.id) {
                this.redirectToEntity(this.caseEntity?.id);
            }
        },

        redirectToEntity(entityId) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    entityId,
                },
            });
        },

        async handleEntityClick(item) {
            // emit original entity data
            this.$emit("onEntityTabClick", item?.data);
        },

        async handleEntityEdit(entity) {
            const { data: entityData } = entity;
            this.loaders.entityData = true;

            // TODO immediately show Edit Window with loader (data fetching may be moved to the CanvasMenu)
            // this.isEditMode = true;

            try {
                const { data } = await axios.get(`/service/main-entity/${entityData.id}`)
                this.entityToEdit = data.data;
            } catch (error) {
                console.error('Failed to fetch entity data:', error);
            }
            this.isEditMode = true;

            this.loaders.entityData = false;
        },

        finishEdit() {
            // TODO
            this.isEditMode = false;
            this.entityToEdit = null;
            this.updateCardStatus();
        },

        updateCardStatus() {
            // TODO handle status update if need
        },
    },
}
</script>
