var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entity-bar flex items-center mb-4 rounded-lg bg-white entity-toolbar w-full h-16"},[(_vm.showPlus)?_c('Plus',{attrs:{"disabled":_vm.plusDisabled},on:{"click":function($event){return _vm.$emit('add')}}}):_vm._e(),(_vm.caseEntity?.id)?_c('Item',{staticClass:"entity-bar__case transition-all",class:{
            'text-main hover:bg-primary-100': !_vm.caseEntity.selected,
            'bg-main text-primary-content': _vm.caseEntity.selected
        },attrs:{"item":_vm.caseEntity,"readonly":_vm.readonly || _vm.caseEntity.readonly,"selected":_vm.caseEntity.selected,"disabled":_vm.caseEntity.disabled,"selectable":_vm.caseEntity.selectable},on:{"remove":function($event){return _vm.$emit('remove', _vm.caseEntity)},"select":function($event){return _vm.$emit('select', _vm.caseEntity)}}}):_vm._e(),_c('div',{staticClass:"flex items-center w-full overflow-hidden gap-4 px-4"},[(_vm.entityItems?.length > 0)?_c('div',{staticClass:"rounded-lg w-9 h-full p-3 flex justify-center",class:{
                'border-2 cursor-pointer transition-all hover:bg-primary-100': !_vm.arrowsDisabled,
                'bg-gray-300 pointer-events-none': _vm.arrowsDisabled
            },on:{"click":function($event){return _vm.scrollItems('left')}}},[_c('font-awesome-icon',{staticClass:"h-6",class:{
                    'text-main': !_vm.arrowsDisabled,
                    'bg-gray-300 text-gray-500 pointer-events-none': _vm.arrowsDisabled
                },attrs:{"icon":"chevron-left"}})],1):_vm._e(),(_vm.entityItems?.length > 0)?_c('div',{ref:"carousel",staticClass:"flex items-center py-2 carousel w-full"},[_c('div',{ref:"scrollableContent",staticClass:"flex items-center gap-4 scrollable-content"},_vm._l((_vm.entityItems),function(item){return _c('Item',{key:item.id,staticClass:"entity-bar__item",class:{
                        'text-base-content': !item.selected,
                        'bg-main text-primary-content rounded-lg': item.selected
                    },attrs:{"item":item,"readonly":_vm.readonly || item.readonly,"selected":item.selected,"disabled":item.disabled,"selectable":item.selectable,"editable":_vm.editable},on:{"remove":function($event){return _vm.$emit('remove', item)},"select":function($event){return _vm.$emit('select', item)},"edit":function($event){return _vm.$emit('edit', item)}}})}),1)]):_vm._e(),(_vm.entityItems?.length > 0)?_c('div',{staticClass:"rounded-lg w-9 h-full border-2 p-3 mr-2 flex justify-center",class:{
                'border-2 cursor-pointer transition-all hover:bg-primary-100': !_vm.arrowsDisabled,
                'bg-gray-300 pointer-events-none': _vm.arrowsDisabled
            },on:{"click":function($event){return _vm.scrollItems('right')}}},[_c('font-awesome-icon',{staticClass:"h-6",class:{
                    'text-main': !_vm.arrowsDisabled,
                    'bg-gray-300 text-gray-500 pointer-events-none': _vm.arrowsDisabled
                },attrs:{"icon":"chevron-right"}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }