var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entity flex items-center justify-center gap-6 min-h-16 pl-7 pr-4 py-3",class:{
        'bg-white': !_vm.item.selected && !_vm.item.warning && !_vm.item.error,
        'bg-error': _vm.item.error && !_vm.item.selected,
        'opacity-50 pointer-events-none cursor-not-allowed': _vm.item.disabled,
        'cursor-pointer': _vm.item.selectable
    },on:{"click":_vm.onSelect}},[_c('div',{staticClass:"entity__content flex items-center justify-center gap-2"},[(_vm.item.type in _vm.entityTypesConfig)?_c('div',{staticClass:"entity__content-underlay",class:{ 'invisible': _vm.item.selected }}):_vm._e(),(_vm.item.type in _vm.entityTypesConfig)?_c('div',{staticClass:"entity__icon-wrapper flex items-center justify-center relative rounded-full"},[_c('span',{staticClass:"flex items-center justify-center w-5 h-5 rounded-full",class:{
                    'bg-primary': !_vm.item.selected,
                    'bg-white': _vm.item.selected
                }},[_c(_vm.entityTypesConfig?.[_vm.item.type]?.icon,{tag:"component",staticClass:"entity__icon",class:{ 'selected': _vm.item.selected }})],1)]):_vm._e(),_c('p',{staticClass:"entity__name mt-1 text-sm font-bold w-48 truncate",attrs:{"title":_vm.itemName}},[_vm._v(" "+_vm._s(_vm.itemName || '--')+" ")])]),(_vm.showControls)?_c('div',{staticClass:"entity__controls ml-auto flex gap-1 items-center"},[(_vm.editable)?_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                content: 'Edit',
            }),expression:"{\n                content: 'Edit',\n            }"}],staticClass:"flex items-center justify-center text-primary",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edit')}}},[_c('EditIcon',{staticClass:"entity__btn-icon",class:{ 'selected': _vm.item.selected }})],1):_vm._e(),(!_vm.readonly)?_c('button',{staticClass:"flex items-center justify-center text-primary",attrs:{"title":"Delete"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove')}}},[_c('TrashIcon',{staticClass:"entity__trash",class:{ 'selected': _vm.item.selected }})],1):_vm._e()]):(_vm.item.checked)?_c('div',{staticClass:"entity__check ml-auto flex items-center justify-center relative rounded-full bg-success"},[_c('font-awesome-icon',{staticClass:"text-primary-content text-xs",attrs:{"icon":"check"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }