<template>
    <div
        class="add-entity-container"
        @click.self="onClose"
    >
        <div class="add-entity-container__content bg-white rounded-xl shadow-xl">
        <template v-if="currentStep === steps.Initial">
            <div class="flex items-center gap-4 py-3 px-4">
                <template v-if="isEditMode">
                <FormulateInput
                    v-model="entityName"
                    type="text"
                    validation="required:trim"
                    error-behavior="live"
                    class="flex-1 add-entity-container__input"
                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                />
                <button
                    type="button"
                    :disabled="applyButtonDisabled"
                    class="flex items-center justify-center w-6 h-6 rounded"
                    :class="{
                        'bg-base-content-100': applyButtonDisabled,
                        'bg-primary': !applyButtonDisabled
                    }"
                    @click="isEditMode = false"
                >
                    <font-awesome-icon class="text-primary-content text-lg" icon="check" />
                </button>
                </template> 

                <template v-else>
                <span class="text-xs add-entity-container__name">Name: <span class="text-sm font-medium">{{ entityName }}</span></span>
                <button
                    type="button"
                    class="flex items-center justify-center w-5 h-5"
                    @click="isEditMode = true"
                >
                    <EditIcon />
                </button>
                <button
                    type="button"
                    class="flex items-center justify-center w-6 h-6 rounded"
                    :class="{
                        'bg-primary': !getIsPlusDisabled,
                        'bg-gray-300': getIsPlusDisabled
                    }"
                    :disabled="getIsPlusDisabled"
                    @click="setStep(steps.TypeSelection)"
                >
                    <font-awesome-icon class="text-lg text-primary-content" :icon="['fa', 'plus']" />
                </button>
                </template> 
            </div>
        </template>

        <template v-if="currentStep === steps.TypeSelection">
            <div class="flex gap-4 items-center py-3 px-4">
                <div class="w-14 h-14 flex items-center justify-center rounded-full bg-gray-200">
                    <font-awesome-icon :icon="['fa', 'plus']" class="h-6 text-main" />
                </div>
                <span class="text-xl">Add</span>
            </div>

            <div class="add-entity-container__buttons p-12 bg-gray-100 rounded-b-xl">
                <template v-for="entity in entityTypeSelectionConfig">
                <button
                    v-if="getEntityRemainingCount(entity.type) > 0"
                    type="button"
                    :key="entity.type"
                    class="flex items-center gap-4 w-full p-5 bg-white border border-gray-400 border-solid"
                    @click="setStep(entity.step)"
                >
                    <component :is="entity.icon" class="add-entity-container__icon" />
                    <span class="text-lg">{{ entity.title }}</span>
                    <font-awesome-icon icon="chevron-right" class="ml-auto text-2xl text-gray-500"  />
                </button>
                </template>
            </div>
        </template>

        <template v-if="currentStep === steps.Individual || currentStep === steps.Organization">
            <div class="flex gap-4 items-center py-3 px-4">
                <button
                    type="button"
                    class="w-14 h-14 flex items-center justify-center rounded-full bg-gray-200"
                    @click="setStep(steps.TypeSelection)"
                >
                    <font-awesome-icon icon="chevron-left" class="h-6 text-main" />
                </button>
                <span class="text-xl">Individual</span>
            </div>

            <div class="add-entity-container__buttons p-12 bg-gray-100 rounded-b-xl">
            <template v-for="entity in entityTypeSelectionConfig">
                <button
                    v-if="entity.step === currentStep"
                    type="button"
                    :key="entity.type"
                    class="flex items-center gap-4 w-full p-5 bg-white border border-gray-400 border-solid"
                    @click="onNewEntityAdd(entity.type)"
                >
                    <component :is="entity.icon" class="add-entity-container__icon" />
                    <span class="text-lg">{{ entity.text }}</span>
                </button>
            </template>
            </div>
        </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
const EditIcon = () => import("@shared/assets/icons/edit.svg");
const PersonIcon = () => import("@shared/assets/icons/entity/person.svg");
const CompanyIcon = () => import("@shared/assets/icons/entity/company.svg");
import { EventBus } from "@/main.js";

export default {
    name: "NewEntityFlow",

    components: {
        EditIcon,
        PersonIcon,
        CompanyIcon,
    },

    data() {
        return {
            currentStep: "",
            entityName: "",
            isEditMode: false,
        }
    },

    mounted() {
        this.currentStep = this.steps.Initial;
        this.entityName = this.getNewEntityInfo?.temporary_name;
    },

    computed: {
        ...mapGetters([
            "getCaseEntitiesData",
            "getNewEntityInfo",
            "getIsPlusDisabled",
        ]),

        steps() {
           return {
               Initial: "initial",
               TypeSelection: "typeSelection",
               Individual: "individual",
               Organization: "organization",
            }
        },

        entityTypes() {
            return {
               Company: "Company",
               Person: "Person",
            }
        },

        entityTypeSelectionConfig() {
            return {
                [this.entityTypes.Person]: {
                    title: "Individual",
                    text: "New Person",
                    type: this.entityTypes.Person,
                    icon: PersonIcon,
                    step: this.steps.Individual,
                },
                [this.entityTypes.Company]: {
                    title: "Organization",
                    text: "New Company",
                    type: this.entityTypes.Company,
                    icon: CompanyIcon,
                    step: this.steps.Organization,
                },
            }
        },

        caseEntitiesTypeInfo() {
            return this.getCaseEntitiesData?.case_entity_type_info ?? {};
        },

        applyButtonDisabled() {
            return !this.entityName.length;
        },
    },

    methods: {
        ...mapActions([ "resetNewEntityInfo" ]),
        ...mapMutations([ "SET_SHOW_ADD_NEW_ENTITY", "SET_NEW_ENTITY_INFO" ]),

        getEntityRemainingCount(entityType) {
            return Object.values(this.caseEntitiesTypeInfo).find(ce => ce.name === entityType)?.remaining;
        },

        setStep(stepName) {
            this.currentStep = stepName;
        },

        setEntityTypeId() {
            const [ entityTypeId ] = Object.entries(this.caseEntitiesTypeInfo)
                .find(([, value]) => value.name === this.getNewEntityInfo.entity_type) ?? [];
            this.SET_NEW_ENTITY_INFO({ key: "entity_type_id", value: entityTypeId });
        },

        onNewEntityAdd(entityType) {
            this.SET_SHOW_ADD_NEW_ENTITY(false);
            this.SET_NEW_ENTITY_INFO({ key: "entity_type", value: entityType });
            this.SET_NEW_ENTITY_INFO({ key: "entity_name", value: this.entityName });

            this.setEntityTypeId();

            EventBus.$emit("onNewEntityAdd");
        },

        onClose() {
            this.SET_SHOW_ADD_NEW_ENTITY(false);
            this.resetNewEntityInfo();
        },
    },
}
</script>

<style lang="scss" scoped>
.add-entity-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0, .35);

    &__content {
        max-width: 800px;
    }

    &__input {
        margin-bottom: 0;
    }

    &__name {
        max-width: 650px;
        overflow: hidden;
        white-space:nowrap; 
        text-overflow: ellipsis;
    }

    &__buttons {
        min-width: 550px
    }

    &__icon {
        fill: var(--theme-color-text-200);
        transform: scale(1.75);
    }
}
</style>
