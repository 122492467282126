<template>
    <div class="neo-layout" :style="{ width: layoutWidth, height: layoutHeight }">
        <div :class="[{ 'loading': loading }, 'neo-layout'] ">
          <slot></slot>
        </div>
        <div 
          v-if="loading"
          class="neo-layout__loader"
        >
            <Loader v-if="showLoader" />
        </div>
    </div>
</template>
<script>
import Loader from "./loader.vue";

export default {
  name: "neo-layout",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    Loader
  },
  computed: {
    layoutWidth() {
      return this.$attrs.width || '100%'
    }, 

    layoutHeight() {
      return this.$attrs.height || '100%'
    }, 
  }
}
</script>
<style lang="scss" scoped>
.neo-layout { 
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.neo-layout .loading {
    opacity: 0.5
}

.neo-layout__loader {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    z-index:999999;
}
</style>